import React from "react";
import AboutVideo from "../AboutVideo/AboutVideo";
import AllServices from "../AllServices/AllServices";
import BgVideo from "../BgVideo/BgVideo";
import Footer from "../Shared/Footer/Footer";
import UpdatedServices from "../updatedService/UpdatedServices";

const Home = () => {
  return (
    <div>
      <BgVideo />
      <UpdatedServices />
      <AboutVideo />
      {/* <OurClient /> */}
      <AllServices />
      <Footer />
    </div>
  );
};

export default Home;
