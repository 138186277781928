import whoWeAre from "../../images/new -images/1-WhoWeAre.jpg";
import Globaly_Coupled_Expert from "../../images/new -images/2- Globaly_Coupled_Expert.jpg";
import Our_360_Offerings from "../../images/new -images/3- Our_360_Offerings.jpg";
import DVP_Clients from "../../images/new -images/4- DVP_Clients.jpg";
import DVP_Partners from "../../images/new -images/5- DVP_Partners.jpg";
import Roadmap from "../../images/new -images/6- Roadmap.jpg";

import "./UpdatedServices.css";
const UpdatedServices = () => {
  return (
    <div className="updated-service-main">
      <div className="updated-service-card">
        <img src={whoWeAre} alt="whoWeAre" />
      </div>
      <div className="updated-service-card">
        <img src={Globaly_Coupled_Expert} alt="whoWeAre" />
      </div>
      <div className="updated-service-card">
        <img src={Our_360_Offerings} alt="Our_360_Offerings" />
      </div>
      <div className="updated-service-card">
        <img src={DVP_Clients} alt="DVP_Clients" />
      </div>
      <div className="updated-service-card">
        <img src={DVP_Partners} alt="DVP_Partners" />
      </div>
      <div className="updated-service-card">
        <img src={Roadmap} alt="Roadmap" />
      </div>
    </div>
  );
};

export default UpdatedServices;
